import { toRefs, reactive, computed } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
import { format } from 'date-fns';
import { startCase } from 'lodash';

const SERVICE_NAME = 'services';
const ARCHIVED_SERVICE_NAME = 'services/services-archived';
const SERVICE_TYPES = [
  { name: 'Clinical Consultation', value: 'clinical-consultation' },
  { name: 'Clinical Procedure', value: 'clinical-procedure' },
  { name: 'Dental', value: 'dental' },
  { name: 'Laboratory', value: 'lab' },
  { name: 'Imaging', value: 'imaging' },
  { name: 'Physical Exam', value: 'pe' },
];

function mapService (service) {
  return {
    ...service,
    formattedCreatedAt: service?.createdAt ? format(new Date(service.createdAt), 'MMM dd, yyyy') : null,
    formattedType: startCase(service?.type),
    formattedPrice: service?.price ? service.price.toFixed(2) : null,
    isArchived: !!service?.archivedAt,
  };
}

const state = reactive({
  services: [],
  archivedServices: [],
});

export function useServices () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  const serviceTypes = computed(() => SERVICE_TYPES);

  async function listItems (serviceType, serviceSubtype, opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
    };

    if (serviceType) query.type = serviceType;
    if (serviceSubtype) query.subtype = serviceSubtype;

    if (opts?.searchString) {
      query.name = { $regex: `^${opts.searchString}`, $options: 'i' };
    }

    const result = await sdk?.list(SERVICE_NAME, query);
    const mapped = result.data.map(mapService);
    state.services = mapped;
    return mapped;
  }

  async function listArchivedItems (serviceType, serviceSubtype, opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      ...opts,
    };
    if (serviceType) query.type = serviceType;
    if (serviceSubtype) query.subtype = serviceSubtype;
    const result = await sdk?.list(ARCHIVED_SERVICE_NAME, query);
    state.archivedServices = result.data.map(mapService);
    return state.archivedServices;
  }

  async function createItem (data) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    // remove all fields with falsy values except for 0
    data = Object.fromEntries(Object.entries(data).filter(([_, v]) => v || v === 0));

    // if type is lab or imaging, set subtype to type and type to diagnostic
    if (data.type === 'lab' || data.type === 'imaging') {
      data.subtype = data.type;
      data.type = 'diagnostic';
    }

    const payload = {
      facility: facilityId,
      ...data,
    };

    const result = await sdk?.create(SERVICE_NAME, payload);
    state.services.unshift(mapService(result));
  }

  async function updateItem (id, payload) {
    const result = await sdk?.update(SERVICE_NAME, id, payload);
    const index = state.services.findIndex((item) => item.id === id);
    state.services[index] = mapService(result);
    if (result?.archivedAt || result?.archivedBy) {
      const archivedIndex = state.archivedServices.findIndex((item) => item.id === id);
      state.archivedServices[archivedIndex] = mapService(result);
    }
  }

  async function removeItem (id) {
    await sdk?.delete(SERVICE_NAME, id);
    const index = state.services.findIndex((item) => item.id === id);
    state.services.splice(index, 1);
  }

  async function archiveItem (service) {
    const { id } = service;

    const payload = {
      archive: true,
    };

    const result = await sdk?.update(SERVICE_NAME, id, payload);
    const index = state.services.findIndex((item) => item.id === id);
    state.services.splice(index, 1);
    state.archivedServices.unshift(mapService(result));
  }

  async function unarchiveItem (service) {
    const { id } = service;

    const payload = {
      archive: false,
    };

    const result = await sdk?.update(ARCHIVED_SERVICE_NAME, id, payload);
    const index = state.archivedServices.findIndex((item) => item.id === id);
    state.archivedServices.splice(index, 1);
    state.services.unshift(mapService(result));
  }

  return {
    serviceTypes,
    ...toRefs(state),
    listItems,
    createItem,
    updateItem,
    removeItem,
    archiveItem,
    unarchiveItem,
    listArchivedItems,
  };
}
